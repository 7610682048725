<template>
  <sm-page-loader v-if="visitedPages.isLoading" />

  <div class="categories" v-else>
    <div class="categories__top-panel">
      <sm-breadcrumbs :items="breadcrumbs" />
      <div class="categories__navigation">
        <sm-categories-dropdown
          header="Ранее посещали"
          :items="lastVisitedPage"
        />

        <sm-categories-dropdown
          header="Часто посещаемые"
          :items="frequentlyVisitedPage"
        />
      </div>

      <sm-input
        v-model="search"
        class="categories__search"
        type="search"
        placeholder="Поиск"
      />
    </div>

    <div v-if="!search" class="categories__lists">
      <template v-for="(category, categoryIndex) in filteredCategories">
        <div
          v-show="category.list.length"
          :key="categoryIndex"
          class="categories__list-wrapper"
        >
          <h4 v-if="category.title" class="categories__list-title">
            {{ category.title }}
          </h4>
          <ul class="categories__list">
            <li
              v-for="(item, categoryListIndex) in category.list"
              :key="categoryListIndex"
              class="categories__item"
            >
              <router-link
                :to="item.route"
                class="categories__link"
                @click.native="
                  registerVisitedPage({
                    group: categoriesGroupName,
                    page: item.route.name,
                  })
                "
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
        </div>
      </template>
    </div>

    <template v-else>
      <template v-for="(categoryItem, index) in filteredAllCategories">
        <template v-if="categoryItem.items.length">
          <h3
            class="categories__lists-header"
            :key="'categories-header_' + index"
          >
            {{ categoryItem.header }}
          </h3>
          <div :key="index" class="categories__lists">
            <template v-for="(category, categoryIndex) in categoryItem.items">
              <div
                v-show="category.list.length"
                :key="categoryIndex"
                class="categories__list-wrapper"
              >
                <h4 class="categories__list-title">
                  {{ category.title }}
                </h4>
                <ul class="categories__list">
                  <li
                    v-for="(item, categoryListIndex) in category.list"
                    :key="categoryListIndex"
                    class="categories__item"
                  >
                    <router-link
                      :to="item.route"
                      class="categories__link"
                      @click.native="
                        registerVisitedPage({
                          group: categoriesGroupName,
                          page: item.route.name,
                        })
                      "
                      >{{ item.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </template>
      </template>
    </template>

    <!-- <div class="categories__lists">
      <div
        v-for="(category, categoryIndex) in filteredCategories"
        :key="categoryIndex"
        class="categories__list-wrapper"
      >
        <h4 class="categories__list-title">{{ category.title }}</h4>
        <ul class="categories__list">
          <li
            v-for="(item, categoryListIndex) in category.list"
            :key="categoryListIndex"
            class="categories__item"
          >
            <router-link
              :to="item.route"
              class="categories__link"
              @click.native="
                registerVisitedPage({
                  group: categoriesGroupName,
                  page: item.route.name,
                })
              "
              >{{ item.name }}</router-link
            >
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('visitedPages');
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
import SmCategoriesDropdown from '@/components/common/SmCategoriesDropdown.vue';
import categories from '/src/constants/categoriesListsItems';

export default {
  name: 'SmCategoriesLists',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmInput,
    SmCategoriesDropdown,
  },

  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },

    listName: {
      type: String,
      required: true,
    },

    categoriesGroupName: {
      type: String,
      required: true,
    },

    // categoriesGroupName: {
    //   type: String,
    //   required: true,
    // },

    // categoriesGroupList: {
    //   type: Array,
    //   required: true,
    // },
  },

  data() {
    return {
      search: '',
    };
  },

  computed: {
    ...mapState(['visitedPages']),

    allCategories() {
      return categories;
    },

    currentCategories() {
      return categories[this.listName];
    },

    categoriesGroupListWithPermissions() {
      const categoriesGroupListFiltered = this.currentCategories.items.map(
        (category) => {
          const list = category.list.filter((item) => {
            if (!item.controllerName) return true;
            return (
              this.$ability.checkPermissions(
                'Table',
                item.controllerName,
                'View'
              ) ||
              this.$ability.checkPermissions(
                'Report',
                item.controllerName,
                'View'
              ) ||
              this.$ability.checkPermissions(
                'Table',
                item.controllerName,
                'CreateMany'
              ) ||
              this.$ability.checkPermissions(
                'Table',
                item.controllerName,
                'CreateContract'
              )
            );
          });
          return {
            title: category.title,
            list,
          };
        }
      );

      return categoriesGroupListFiltered.filter(
        (category) => category.list.length
      );
    },

    allCategoriesGroupListWithPermissions() {
      const keys = Object.keys(this.allCategories);
      const allCategoriesGroupListFiltered = keys.map((key) => {
        const category = this.allCategories[key].items;
        const categoryFilteredByPermissions = category.map((item) => {
          const listFiltered = item.list.filter((elem) => {
            return (
              this.$ability.checkPermissions(
                'Table',
                elem.controllerName,
                'View'
              ) ||
              this.$ability.checkPermissions(
                'Report',
                elem.controllerName,
                'View'
              ) ||
              this.$ability.checkPermissions(
                'Import',
                elem.controllerName,
                'Perform'
              ) ||
              this.$ability.checkPermissions(
                'Table',
                elem.controllerName,
                'Perform'
              ) ||
              this.$ability.checkPermissions(
                'Settings',
                elem.controllerName,
                'View'
              )
            );
          });
          return {
            title: item.title,
            list: listFiltered,
          };
        });
        const categoryFiltered = categoryFilteredByPermissions.filter(
          (category) => category.list.length
        );

        return {
          header: this.allCategories[key].header,
          items: categoryFiltered,
        };
      });

      return allCategoriesGroupListFiltered;
    },

    filteredCategories() {
      return this.categoriesGroupListWithPermissions.map((category) => {
        let list = category.list.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
        return {
          title: category.title,
          list,
        };
      });
    },

    filteredAllCategories() {
      return this.allCategoriesGroupListWithPermissions.map((category) => {
        const categoryItems = category.items.map((item) => {
          const list = item.list.filter((item) =>
            item.name.toLowerCase().includes(this.search.toLowerCase())
          );
          return {
            title: item.title,
            list,
          };
        });
        const items = categoryItems.filter((item) => item.list.length);
        return {
          header: category.header,
          items,
        };
      });
    },

    allPages() {
      return this.currentCategories.items
        .map((category) => category.list)
        .flat();
    },

    frequentlyVisitedPage() {
      if (
        this.visitedPages.data &&
        this.visitedPages.data.frequentlyVisited &&
        this.visitedPages.data.frequentlyVisited.length
      ) {
        const { frequentlyVisited } = this.visitedPages.data;

        return this.allPages
          .filter((page) =>
            frequentlyVisited.map((item) => item).includes(page.route.name)
          )
          .map((page) => {
            return {
              ...page,
              visitCount: frequentlyVisited.find(
                (item) => page.route.name === item
              ).visitCount,
            };
          })
          .sort((a, b) => {
            return b.visitCount - a.visitCount;
          });
      }

      return [];
    },

    lastVisitedPage() {
      if (
        this.visitedPages.data &&
        this.visitedPages.data.lastVisited &&
        this.visitedPages.data.lastVisited.length
      ) {
        const { lastVisited } = this.visitedPages.data;
        return this.allPages
          .filter((page) =>
            lastVisited.map((item) => item).includes(page.route.name)
          )
          .sort((a, b) => {
            // Приведение сортировки отфильтрованных страниц
            // Как полученных с бэка
            // Вверху списка последняя открытая страница
            return (
              lastVisited.findIndex((visited) => visited === a.route.name) -
              lastVisited.findIndex((visited) => visited === b.route.name)
            );
          })
          .map((page) => {
            return {
              ...page,
              visitCount: lastVisited.find((item) => page.route.name === item)
                .visitCount,
            };
          });
      }

      return [];
    },
  },

  created() {
    this.getPages({ groupName: this.categoriesGroupName });
  },

  methods: {
    ...mapActions(['getPages', 'registerVisitedPage']),
  },
};
</script>

<style lang="scss">
.categories__top-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 15px;
}

.categories__navigation {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
}

.categories__search {
  width: 100%;
  max-width: 350px;
}

.categories__lists-header {
  margin-bottom: 15px;
}

.categories__lists {
  margin-bottom: 20px;
  column-gap: 100px;

  @for $i from 1 through 9 {
    @media only screen and (min-width: #{440 + $i * 380}px) {
      columns: $i + 1;
    }
  }
}

.categories__list-wrapper {
  margin-bottom: 20px;
  width: 280px;
  break-inside: avoid;
}

.categories__list-title {
  margin-bottom: 10px;

  font-weight: 600;
  color: var(--blue);
}

.categories__item {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.categories__link {
  color: var(--black);

  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--blue);
  }
}
</style>
